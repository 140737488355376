/*
 * Skin: Black
 * -----------
 */
@import "../../bootstrap-less/mixins.less";
@import "../../bootstrap-less/variables.less";
@import "../variables.less";
@import "../mixins.less";

/* skin-black navbar */
.skin-black {
  //Navbar & Logo 
  .main-header {
    .box-shadow(0px 1px 1px rgba(0, 0, 0, 0.05));
    .navbar-toggle {
      color: #333;
    }
    .navbar-brand {
      color: #333;
      border-right: 1px solid #eee;
    }
    > .navbar {
      .navbar-variant(#fff; #333; #999; #fff);
      > .sidebar-toggle {
        color: #333;
        border-right: 1px solid #eee;
      }
      .navbar-nav {
        > li > a {
          border-right: 1px solid #eee;
        }
      }
      .navbar-custom-menu .navbar-nav,
      .navbar-right {
        > li {
          > a {
            border-left: 1px solid #eee;
            border-right-width: 0;
          }
        }
      }
    }
    > .logo {
      .logo-variant(#fff; #333);
      border-right: 1px solid #eee;
      @media (max-width: @screen-header-collapse) {
        .logo-variant(#222; #fff);
        border-right: none;
      }
    }

    li.user-header {
      background-color: #222;
    }
  }

  //Content Header
  .content-header {
    background: transparent;
    box-shadow: none;
  }
  //User Panel (resides in sidebar)
  .user-panel {
    > .image > img {
      border: 1px solid #444;
    }

    > .info, > .info > a {
      color: #eee;
    }
  }
  //Sidebar & Treeview
  // Sidebar color
  .main-sidebar,
  .left-side,
  .wrapper {
    background: #222;
  }
  // the menu
  .sidebar > .sidebar-menu > li {
    &.header {
      background: darken(#222, 2%);
      color: rgba(255, 255, 255, .4);
    }
    > a {
      margin-right: 1px;
      border-left: 3px solid transparent;
    }
    //Hover and active states
    > a:hover,
    &.active > a {
      color: #fff;
      background: #444;
      border-left-color: #fff;
    }

    > .treeview-menu {
      background: #333;
    }
  }

  .sidebar a {
    color: #eee;
    &:hover {
      text-decoration: none;
    }
  }
  // skin blue treeview-menu
  .treeview-menu {
    > li {
      > a {
        color: #ccc;
      }
      &.active > a,
      > a:hover {
        color: #fff;
      }
    }
  }

  //Sidebar Search Form
  .sidebar-form {
    .border-radius(3px);
    border: 1px solid lighten(#222, 10%);
    margin: 10px 10px;
    input[type="text"], .btn {
      box-shadow: none;
      background-color: lighten(#222, 10%);
      border: 1px solid transparent;
      height: 35px;
      .transition(all @transition-speed @transition-fn);
    }
    input[type="text"] {
      color: #666;
      .border-radius(2px, 0, 2px, 0) !important;
      &:focus, &:focus + .input-group-btn .btn {
        background-color: #fff;
        color: #666;
      }
      &:focus + .input-group-btn .btn {
        border-left-color: #fff;
      }
    }
    .btn {
      color: #999;
      .border-radius(0, 2px, 0, 2px) !important;
    }
  }
}